import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "../Infaestructure/AdapterConfigure";

export class useCaseLoadQuestionPreview {
    private repository: RepositoryMain;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.languageDefault = 'es';
    }

    public async exec(id: string): Promise<EntityLoadQuestion> {
        const finded = await this.repository.getRegister(id);
        const payload: EntityLoadQuestion = {
            AutoChequeoTipologia: [],
            AutochequeoTitulos: [],
            AutochequeoItems: [],
            cierreControlPreventivoHasQuestion: false
        }

        if (!finded) return payload;

        const { GrupoPreguntas } = finded;
        
        payload.AutoChequeoTipologia = GrupoPreguntas.map((row, index) => ({
            _id: '',
            InformacionTipologia: '',
            CodigoTipologia: '',
            Momento: { IdMomento: 0, Momento: '' },
            Orden: index,
            FamiliaTipologia: { IdFamiliaTipologia: 0, Familia: '' },
            IdTipologia: index,
            TipologiaTrabajo: '',
            RiesgosEspeciales: 0,
            IdPais: 0,
            IdDelegacion: 0,
            IdActividad: 0,
        }))

        payload.AutochequeoTitulos = GrupoPreguntas.map((row, index) => ({
            _id: `${row.IdTitulo}`,
            IdTitulo: row.IdTitulo,
            Titulo: row.Titulo,
            Estado: { Estado: 'A', IdEstado: 1 },
            Actividad: { Actividad: finded.Actividad, IdActividad: finded.IdActividad, Codigo: '' },
            Delegacion: { Codigo: '', Delegacion: finded.Delegacion, IdDelegacion: finded.IdDelegacion },
            Momento: { Momento: '', IdMomento: 0 },
            Pais: { IdPais: finded.Pais.IdPais, Pais: finded.Pais.Pais },
            Template: '',
            Tipologia: { IdTipologia: 0, Tipologia: '', CodigoTipologia: '', TipologíaTrabajo: '' },
            Requerimientos: { Fotografia: 0, Observacion: 0, ObservacionCPT: 0, FotografiaCPT: 0 },
            Orden: index,
            CodigoTitulo: `${row.IdTitulo}`,
            adittionalIdTitle: [row.IdTitulo]
        })) 

        payload.AutochequeoItems = GrupoPreguntas.flatMap((_row, _index) => _row.Preguntas.filter(row => !!row.IdPregunta).map((row, index) => ({
            _id: `${row.IdPregunta}`,
            IdRegistro: row.IdPregunta,
            Item: row.Item,
            Help: row.Help,
            Momento: { Momento: '', IdMomento: 0 },
            Disponibilidad: [AdapterConfigure.KEYS_FORM.specialInputs.firmaJefeObra, AdapterConfigure.KEYS_FORM.specialInputs.audio].includes(row.TypeInput ?? '') ? '[]' :JSON.stringify([
                { Respuesta: 'SI', Estados: row.ResEstado ? 1 : 0, Observacion: row.ObservacionIngresada ? 1 : 0, NombreFoto: '', CantidadFotos: row.NombreFotos?.length ?? 0, Personal: 0 },
                { Respuesta: 'NO', Estados: row.ResEstado ? 1 : 0, Observacion: row.ObservacionIngresada ? 1 : 0, NombreFoto: '', CantidadFotos: row.NombreFotos?.length ?? 0, Personal: 0 },
                { Respuesta: 'NA', Estados: row.ResEstado ? 1 : 0, Observacion: row.ObservacionIngresada ? 1 : 0, NombreFoto: '', CantidadFotos: row.NombreFotos?.length ?? 0, Personal: 0 },
            ]),
            Estados: JSON.stringify([
                { Respuesta: 'Bien', Estados: 0, Observacion: row.ObservacionIngresada ? 1 : 0, NombreFoto: '', CantidadFotos: row.NombreFotos?.length ?? 0, Personal: 0, typeInput: 'EstadoPreview' },
                { Respuesta: 'Mal', Estados: 0, Observacion: row.ObservacionIngresada ? 1 : 0, NombreFoto: '', CantidadFotos: row.NombreFotos?.length ?? 0, Personal: 0, typeInput: 'EstadoPreview' },
            ]),
            VisibilidadPregunta: 1,
            OpcionesCierreCP: '',
            IdPais: 0,
            IdDelegacion: 0,
            IdActividad: 0,
            TipologiaArray: JSON.stringify([
                { IdTipologia: _index, TipologiaTrabajo: '', Titulo: { IdTitulo: _row.IdTitulo, Titulo: _row.Titulo, CodigoTitulo: `${_row.IdTitulo}` }, CodigoTipologia: '', Orden: _index }
            ]),
            Orden: row.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.firmaJefeObra ? 9999999 : index,
            Disabled: false,
            TypeInput: row.TypeInput ?? ''
        })));

        return payload;
    }

}