import { EntityFormatos } from "../../../shared/Domain/CatalogosV2/EntityFormatos";
import { EntityAutochequeoItems } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadQuestionsCCP {
    private repository: RepositoryMain;
    private IdMomentoCierreControlPreventivo: number;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoCierreControlPreventivo = 5;
        this.languageDefault = 'es';
    }

    public async exec(OT: EntityOTs, isV2: boolean): Promise<EntityLoadQuestion> {
        const [AutochequeoTitulos, AutochequeoItems, Formatos] = await Promise.all([
            this.getAutochequeoTitulos(OT),
            this.getAutochequeoItems(OT),
            this.getFormatosV2(OT.Delegacion.IdDelegacion, OT.Pais.IdPais, OT.Actividad.IdActividad)
        ]);

        return ({
            AutoChequeoTipologia: [],
            AutochequeoTitulos: isV2 ? Formatos.titulos : AutochequeoTitulos,
            AutochequeoItems: isV2 ? Formatos.preguntas : AutochequeoItems
        })
    }

    private async getAutochequeoTitulos(OT: EntityOTs) {
        return [{
            _id: "",
            IdTitulo: -1,
            adittionalIdTitle: [-1],
            Titulo: LanguageTranslate().moduloAutoChequeo.form.navBarTextCierreControlPreventivo.toUpperCase(),
            Estado: { "IdEstado": 1, "Estado": "ACTIVO" },
            Actividad: { "IdActividad": -1, "Codigo": "", "Actividad": "" },
            Delegacion: { "IdDelegacion": -1, "Codigo": "", "Delegacion": "" },
            Momento: { "IdMomento": 5, "Momento": "POST JOB" },
            Pais: { "IdPais": -1, "Pais": "", "Codigo": "" },
            Template: "",
            Tipologia: { "IdTipologia": -1, "CodigoTipologia": "", "TipologíaTrabajo": "" },
            Requerimientos: { "Fotografia": 1, "Observacion": 1 },
            Orden: 1,
            CodigoTitulo: ""
        }]
    }

    private async getAutochequeoItems(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoItems();
        return arr.filter(
            (item) =>
                item.IdActividad === OT.Actividad.IdActividad &&
                item.IdDelegacion === OT.Delegacion.IdDelegacion &&
                item.IdPais === OT.Pais.IdPais &&
                [this.IdMomentoCierreControlPreventivo].includes(item.Momento.IdMomento)
        ).map(row => ({
            ...row,
            Disponibilidad: row.Disponibilidad ?? '[]',
            OpcionesCierreCP: this.validateAnswers(row.OpcionesCierreCP, row.Disponibilidad) || '[]'
        }));
    }

    private validateAnswers(opcionesCierreCP: string, disponibilidad: string): string {
        const _disponibilidad: Array<any> = disponibilidad ? JSON.parse(disponibilidad) : [];
        return _disponibilidad.length === 0 ? opcionesCierreCP : disponibilidad
    }

    private async getFormatosV2(IdDelegacion: number, IdPais: number, IdActividad: number) {
        let dataActividad = {
            _id: '',
            Actividad: '',
            Codigo: '',
            IdActividad: 0
        };

        let dataDelegacion = {
            _id: '',
            IdDelegacion: 0,
            Codigo: '',
            Delegacion: '',
        }

        let dataPais = {
            _id: '',
            IdPais: 0,
            Codigo: '',
            Pais: '',
        }

        const response = (await this.repository.getFormatosV2()).filter(row =>
            // row.delegacionInfo?.IdDelegacion === IdDelegacion &&
            row.actividadInfo.IdActividad === IdActividad &&
            row.paisInfo.IdPais === IdPais
        ).reduce((prev, current) => {
            dataActividad = current.actividadInfo;
            dataDelegacion = current.delegacionInfo;
            dataPais = current.paisInfo;

            return [
                ...prev,
                ...current.familias.filter(row => row.momento.Codigo === AdapterKeyGeneral.v2.codigoMomentoCierrecontrolPreventivo && row.tipologias.length > 0).map(row => ({ ...row, _idRegistroPadre: current._id }))
            ]
        }, [] as EntityFormatos["familias"]);

        // Formatear data
        const FormatEstadoName: Record<string, string> = {
            B: "Bien",
            M: "Mal"
        }

        const titulos: EntityAutochequeoTitulos[] = response.flatMap((familia, indexFamilia) => familia.tipologias.filter(row => row.titulos.length > 0).flatMap((tipologia) => tipologia.titulos.filter(tipologia => !!tipologia.preguntas.length).map((row, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: dataActividad.Actividad,
                Codigo: dataActividad.Codigo,
                IdActividad: dataActividad.IdActividad
            },
            CodigoTitulo: row._id,
            Delegacion: {
                Codigo: dataDelegacion.Codigo,
                Delegacion: dataDelegacion.Delegacion,
                IdDelegacion: dataDelegacion.IdDelegacion
            },
            Estado: {
                Estado: 'Activo',
                IdEstado: 1
            },
            IdTitulo: row._id as any,
            Momento: {
                IdMomento: familia.momento._id as any,
                Momento: 'Lista Autochequeo',
            },
            Orden: (row.orden ?? (index + 1)) + ((indexFamilia + 1) * 100),
            Pais: {
                IdPais: dataPais.IdPais,
                Pais: dataPais.Pais
            },
            Requerimientos: {
                Fotografia: row.requiereFoto ? 1 : 0,
                Observacion: row.requiereObservacion ? 1 : 0
            },
            Template: '',
            Tipologia: {
                CodigoTipologia: tipologia._id,
                IdTipologia: tipologia._id as any,
                TipologíaTrabajo: familia.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
            },
            Titulo: row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
            adittionalIdTitle: [row._id],
        }) as EntityAutochequeoTitulos))).sort((rowA, rowB) => rowA.Orden - rowB.Orden);

        const preguntas: EntityAutochequeoItems[] = response.flatMap(familia => familia.tipologias.flatMap((tipologia) => tipologia.titulos.flatMap(titulo => titulo.preguntas.filter(preguntas => preguntas.disponibilidad.filter(row => !row.ocultar).length).map((preguntas, indexPreguntas) => {
            const findTitulo = titulos.find(title => title._id === preguntas._idTitulo);

            return {
                _id: preguntas._id,
                Disponibilidad: JSON.stringify(preguntas.disponibilidad.filter(row => !row.ocultar).map(row => ({
                    "Respuesta": row.item.toUpperCase(),
                    "Estados": row.mostrarEstados,
                    "Observacion": row.ingresarObservacion,
                    "EnviarCorreo": row.enviarCorreo,
                    "CharlaPreTarea": row.charlaPreTarea,
                    "DevolverOrden": row.orden,
                    "NombreFoto": "",
                    "CantidadFotos": row.cantidadFotos,
                    "IdHijo": 0
                }))),
                Estados: JSON.stringify(preguntas.estados.filter(row => !row.ocultar).map(row => ({
                    "Respuesta": FormatEstadoName[row.item] ?? row.item,
                    "DevolverOrden": row.orden,
                    "Observacion": row.observacion ? 1 : 0,
                    "EnviarCorreo": row.enviarCorreo,
                    "NombreFoto": "",
                    "CantidadFotos": row.cantidadFotos,
                    "IdHijo": 0
                }))),
                Help: preguntas.iteminIdioma.find(row => row.codigo === this.languageDefault)?.help ?? '',
                IdActividad: IdActividad,
                IdDelegacion: IdDelegacion,
                IdPais: IdPais,
                IdRegistro: preguntas._id as any,
                Item: preguntas.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
                Momento: {
                    IdMomento: familia.momento._id as any,
                    Momento: 'Lista Autochequeo',
                },
                OpcionesCierreCP: JSON.stringify(preguntas.disponibilidad.filter(row => !row.ocultar).map(row => ({
                    "Respuesta": row.item.toUpperCase(),
                    "Estados": row.mostrarEstados,
                    "Observacion": row.ingresarObservacion,
                    "EnviarCorreo": row.enviarCorreo,
                    "CharlaPreTarea": row.charlaPreTarea,
                    "DevolverOrden": row.orden,
                    "NombreFoto": "",
                    "CantidadFotos": row.cantidadFotos,
                    "IdHijo": 0
                }))),
                Orden: (preguntas.orden ?? (indexPreguntas + 1)) + ((findTitulo?.Orden ?? 0) * 100),
                TipologiaArray: JSON.stringify([
                    {
                        "IdTipologia": tipologia?._id ?? '',
                        "CodigoTipologia": tipologia?._id ?? '',
                        "Orden": 1,
                        "Titulo": {
                            "IdTitulo": findTitulo?._id,
                            "Titulo": findTitulo?.Titulo,
                            "CodigoTitulo": findTitulo?._id
                        }
                    }
                ]),
                VisibilidadPregunta: 1,
            } as EntityAutochequeoItems
        }
        )))).sort((rowA, rowB) => rowA.Orden - rowB.Orden);

        return {
            titulos,
            preguntas
        }
    }
}