export const AdapterKeyGeneral = {
    TIPO_EMPRESA: 1,
    TIPO_SUBCONTRATA: 2,
    TIPO_CLIENTE: 3,
    ID_GRUPO_COBRA: 1,
    CODIGO_GRUPO_COBRA: '01',
    v2: {
        codigoMomentoListaAutochequeo: "01",
        codigoMomentoControlPreventivo: "02",
        codigoMomentoCierrecontrolPreventivo: "04",
    }
}