//Dependency External
import { LanguageTranslate } from '..//Infraestructure/LanguageTranslate';
import { Navbar, Offcanvas } from 'react-bootstrap';

// Imagenes
import imgLogo from '../../../assets/img/IconosAppSGSOMovil/ic_nuevo2_round.png';
import imgUser from '../../../assets/img/user.png';
import imgNoSignal from '../../../assets/img/noSignal.png';

//Style
import './ObjectHeader.scss';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { useSelector } from 'react-redux';
import { RootState } from '../Infraestructure/AdapterStore';

interface ViewProps {
    auth: boolean;
    user: EntityDataUsuario;

    iconMenu: boolean;
    onHideIconMenu: Function;
    onShowIconMenu: Function;

    online: boolean;
    countProcess: number;
    countNotifications: number;

    onClickHome?: Function;
    onClickHelp?: Function;
    onClickReport?: Function;
    onClickProfile?: Function;
    onClickVariable?: Function;
    onClickLogout?: Function;
    onClickNotification?: Function;
    onClickReloadApp: Function;
    showSaludo: boolean;
};

export const ObjectHeader = (props: ViewProps) => {
    const languageTranslate = LanguageTranslate();
    const { appv2 } = useSelector((state: RootState) => state.auth.preferencia)

    return (
        <div className='ObjectHeader'>
            <Navbar bg="primary">
                <div className="container-fluid">
                    <div className="navbar-title text-center">
                        <img className='img-thumbnail' style={{ borderRadius: '50%' }} src={imgLogo} alt="Logo" width={props.showSaludo ? 100 : 70} />
                        {/* <h6 className="mb-0 fw-bold text-white text-truncate pt-2" style={{ fontSize: '11px' }}>
                            {languageTranslate.nombreProyecto}
                        </h6> */}
                        {
                            props.showSaludo
                                ? <>
                                    <h3 className='text-white' style={{ paddingTop: '10px' }}>{languageTranslate.moduloMain.textoBienvenida}</h3>
                                    <h6 className='text-white'>{`${props.user.Nombres} ${props.user.ApellidoPaterno} ${props.user.ApellidoMaterno}`.trim()}</h6>
                                </>
                                : <div className='pb-2'></div>
                        }
                    </div>
                </div>

                <div className="navbar-offline" hidden={props.online}>
                    <div>
                        <img className='imgNoSignal' src={imgNoSignal} alt="noSignal" width={20} />
                        <label className='count-offline'>{props.countProcess}</label>
                    </div>
                </div>

                <div className='navbar-profile' onClick={() => props.onShowIconMenu()}>
                    <div className='profile d-flex align-items-center' style={{ cursor: 'pointer' }} >
                        <i className="fa-solid fa-bars text-white userIcon" />
                        {
                            props.countNotifications > 0 ?
                                <div className='badget-count-notification badget-count-notificacion-position'>
                                    { props.countNotifications > 9 ? '+9' : props.countNotifications }
                                </div>
                            : null
                        }
                    </div>
                </div>

                <Offcanvas show={props.iconMenu} onHide={props.onHideIconMenu} >
                    <Offcanvas.Header className=''>
                        <div className='header-container-sidebar'>
                            <img className='imgLogoSidebar shadow' src={imgLogo} alt="logo" width={30} />
                            <span className='spanNombreSistema'>Safe IS</span>
                        </div>

                        <div className='header-container-sidebar' onClick={() => !!props.onClickProfile ? props.onClickProfile() : undefined}>
                            <img title={props.user?.Usuario} src={imgUser} alt="userIcon" width="30" className="imgLogoSidebar shadow" />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='spanNombreUsuario'>{`${props.user.Nombres} ${props.user.ApellidoPaterno} ${props.user.ApellidoMaterno}`.trim()}</span>
                                {/* <span className='spanDetalleUsuario'>{`${props.user.Empresa?.Nombre || props.user.Empresa['Empresa' as keyof typeof props.user.Empresa] || ''} - ${props.user.PaisBase.Pais}`}</span> */}
                                <span className='spanDetalleUsuario'>{`${props.user.Perfil.Name} - ${props.user.TipoPerfil.Name}`}</span>
                                <span className='spanDetalleUsuario text-uppercase'>{`${props.user.DatosRol?.Rol}`}</span>
                            </div>
                        </div>

                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickHome ? props.onClickHome() : undefined}>
                            <i className='fa-solid fa-home iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoInicio}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickHelp ? props.onClickHelp() : undefined}>
                            <i className='fa-regular fa-circle-question iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoAyuda}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickReport ? props.onClickReport() : undefined}>
                            <i className='fa-solid fa-bug iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoReporte}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickVariable ? props.onClickVariable() : undefined}>
                            <i className='fa-solid fa-table iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.textoCatalogos}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => props.onClickReloadApp()}>
                            <i className='fa-solid fa-rotate iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoRefrescarApp}</span>
                        </div>
                        <div className='body-container-sidebar' style={{ position: 'relative' }} onClick={() => !!props.onClickNotification ? props.onClickNotification() : undefined}>
                            <i className='fa-regular fa-bell iconOpcion' />
                            {
                                props.countNotifications > 0 ?
                                    <div className='badget-count-notification badget-count-notification-position-drawer'>
                                        { props.countNotifications > 9 ? '+9' : props.countNotifications }
                                    </div>
                                : null
                            }
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoNotificacion}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickLogout ? props.onClickLogout() : undefined}>
                            <i className='fa fa-power-off iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.textoCerrarSesion}</span>
                        </div>
                        <span className='AppVersion'>
                            v.{process.env.REACT_APP_VERSION_SYSTEM}
                        </span>
                    </Offcanvas.Body>
                </Offcanvas>

            </Navbar>
        </div>
    )
}