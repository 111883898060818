import { EntityFormatos } from "../../../shared/Domain/CatalogosV2/EntityFormatos";
import { EntityAutochequeoTipologia } from "../../../shared/Domain/EntityAutochequeoTipologia";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";
import { LanguageApp } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadTipologiaCP {
    private repository: RepositoryMain;
    private IdMomentoControlAutochequeo: number;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.languageDefault = LanguageApp();
        this.IdMomentoControlAutochequeo = 2;
    }

    public async exec(OT: EntityOTs, IdActividad: number, isV2?: boolean): Promise<EntityLoadQuestion> {

        const [AutoChequeoTipologia] = await Promise.all([
            isV2 ? this.getAutochequeoTipologia_v2(OT?.Delegacion.IdDelegacion, OT.Pais.IdPais, IdActividad) : this.getAutochequeoTipologia(OT)
        ]);

        return ({
            AutoChequeoTipologia,
            AutochequeoTitulos: [],
            AutochequeoItems: []
        })
    }

    private async getAutochequeoTipologia(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoTipologia();
        return arr.filter(item =>
            item.IdActividad === OT.Actividad.IdActividad &&
            item.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.IdPais === OT.Pais.IdPais &&
            [this.IdMomentoControlAutochequeo].includes(item.Momento.IdMomento));
    }

    private async getAutochequeoTipologia_v2(IdDelegacion: number, IdPais: number, IdActividad: number) {

        const response = (await this.repository.getFormatosV2()).filter(row =>
            row.actividadInfo.IdActividad === IdActividad &&
            row.paisInfo.IdPais === IdPais
        ).reduce((prev, current) => {
            return [
                ...prev,
                ...current.familias.filter(row => row.momento.Codigo === AdapterKeyGeneral.v2.codigoMomentoControlPreventivo && row.tipologias.length > 0)
            ]
        }, [] as EntityFormatos["familias"]).sort((rowA, rowB) => (rowA?.orden ?? 1) - (rowB?.orden ?? 1));

        // Formatear tipología
        const tipologias: EntityAutochequeoTipologia[] = response.flatMap((familia, indexFamilia) => familia.tipologias.map((row, index) => ({
            _id: row._id,
            CodigoTipologia: row._id,
            FamiliaTipologia: {
                Familia: familia.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
                IdFamiliaTipologia: familia._id as any
            },
            IdActividad: IdActividad,
            IdDelegacion: IdDelegacion,
            IdPais: IdPais,
            IdTipologia: row._id as any,
            InformacionTipologia: '',
            Momento: {
                IdMomento: familia.momento._id as any,
                Momento: 'Lista Autochequeo',
            },
            Orden: (row.orden ?? (index + 1)) + ((indexFamilia + 1) * 100),
            RiesgosEspeciales: row.esCritico ? 1 : 0,
            TipologiaTrabajo: row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? row.iteminIdioma[0]?.nombre ?? ''
        } as EntityAutochequeoTipologia))).sort((rowA, rowB) => rowA.Orden - rowB.Orden);

        return tipologias;
    }
}