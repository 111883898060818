import { EntityActividad } from "../../../shared/Domain/EntityActividad";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { EntityPais } from "../../../shared/Domain/EntityPais";
import { EntityPersonal, EntityPersonalxPais } from "../../../shared/Domain/EntityPersonal";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { DtoSelectTipoIncidente, EntityTipoIncidente } from "../../../shared/Domain/Catalogos/EntityTipoIncidente";
import { EntityFactorRiesgo } from "../../../shared/Domain/Catalogos/EntityFactorRiesgo";
import { EntityLiteEmpresa } from "../../../shared/Domain/CatalogosLite/EntityLiteEmpresa";
import { EntityLitePersonal, EntityLitePersonalxPais } from "../../../shared/Domain/CatalogosLite/EntityLitePersonal";
import { EntityLiteOT } from "../../../shared/Domain/CatalogosLite/EntityLiteOT";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

export interface EntityLoadData {
    Pais: EntitySelectBase<EntityPais>[];
    Delegacion: EntitySelectBase<EntityDelegacion>[];
    Empresa: EntitySelectBase<EntityEmpresa>[];
    Subcontrata: EntitySelectBase<EntityEmpresa>[];
    Cliente: EntitySelectBase<EntityEmpresa>[];
    Actividad: EntitySelectBase<EntityActividad>[];
    Personal: EntityPersonalxPais;
    TipoIncidencia: DtoSelectTipoIncidente[];
    FactorRiesgo: EntitySelectBase<EntityFactorRiesgo>[];
    TipoIncidente: any[];
    OT: EntitySelectBase<EntityOTs>[]
    Standar: any[];
}

export const initEntityLoadData: EntityLoadData = {
    Pais: [],
    Delegacion: [],
    Empresa: [],
    Cliente: [],
    Subcontrata: [],
    Actividad: [],
    Personal: [],
    FactorRiesgo: [],
    TipoIncidente: [],
    TipoIncidencia: [],
    OT: [],
    Standar: []
}

export interface EntityLoadDataGuest {
    Subcontrata: EntitySelectBase<EntityLiteEmpresa>[];
    Empresa: EntitySelectBase<EntityLiteEmpresa>[]
    Cliente: EntitySelectBase<EntityLiteEmpresa>[]
    Personal: EntityLitePersonalxPais;
    TipoIncidencia: DtoSelectTipoIncidente[];
    OT: EntitySelectBase<EntityLiteOT>[];
    FactorRiesgo: EntitySelectBase<EntityFactorRiesgo>[];
    TipoIncidente: any[];
    Standar: any[];
}

export const initEntityLoadDataGuest: EntityLoadDataGuest = {
    Subcontrata: [],
    Empresa: [],
    Cliente: [],
    Personal: {},
    TipoIncidencia: [],
    OT: [],
    FactorRiesgo: [],
    TipoIncidente: [],
    Standar: []
}

export interface EntityModal {
    images: EntityModalImage[];
    imagesSubsanacion: EntityModalImage[];
    show:   boolean;
    type: 'images' | 'imagesSubsanacion'
}

export interface EntityModalImage {
    file:       File | string;
    type:        'Local' | 'BD';
    valueToShow: string;
}

export interface EntityConfigForm {
    loading: boolean;
    mode: EntityModeForm;
    keyTab: number;
};

export type EntityModeForm = 'view' | 'create' | 'createGuest' | 'viewGuest' | 'levantamiento' | '';

export const initEntityConfigForm: EntityConfigForm = {
    loading: true,
    mode: '',
    keyTab: 1
}

export interface EntityDataForm {
    // edit
    _id?: string;
    IdIncidente?: number;
    IdFecha?: number;

    IdAppLocal: string;

    // Step 1 - Guest
    OTGuest?: EntitySelectBase<EntityLiteOT>;
    SubContrataGuest?: EntitySelectBase<EntityLiteEmpresa>;
    UsuarioRegistroGuest?: EntitySelectBase<EntityLitePersonal>;
    UsuarioMPGuest?: EntitySelectBase<EntityLitePersonal>;

    // Step 1
    OT: EntitySelectBase<EntityOTs>;
    Pais: EntitySelectBase<EntityPais>;
    Delegacion: EntitySelectBase<EntityDelegacion>;
    Empresa: EntitySelectBase<EntityEmpresa>;
    Subcontrata: EntitySelectBase<EntityEmpresa>;
    Cliente: EntitySelectBase<EntityEmpresa>;
    Actividad: EntitySelectBase<EntityActividad>;
    UsuarioRegistro: EntitySelectBase<EntityPersonal>;

    // Step 2
    FechaIncidente: string;
    TipoIncidencia: EntitySelectBase<EntityTipoIncidente>;
    BreveDescripcionIncidente: string;
    MedidasPropuestas: string;
    UsuarioMP: EntitySelectBase<EntityPersonal>;
    Obra: string;
    Provincia: string;
    Direccion: string;
    FactorRiesgo: EntitySelectBase<any>;
    altoRiesgo?: boolean;
    TipoIncidente: EntitySelectBase<{ IdTipoIncidente: number; Incidente: string; Abreviatura: string; }>;
    CondicionInsegura: EntitySelectBase<any>;
    VehiculoAfectado: EntitySelectBase<any>;
    ParalizacionTrabajo: EntitySelectBase<any>;
    Placa: string;
    File?: any;

    DescripcionSolucion: string;
    FileSubsanacion?: any;
}

export const initEntityDataForm: EntityDataForm = {
    IdAppLocal: '',
    OT: null as any,
    Pais: null as any,
    Delegacion: null as any,
    Empresa: null as any,
    Cliente: null as any,
    Actividad: null as any,
    UsuarioRegistro: null as any,
    FechaIncidente: AdapterGenerico.getFechaISO_Local().substring(0, 16),
    TipoIncidencia: null as any,
    BreveDescripcionIncidente: "",
    FactorRiesgo: null as any,
    TipoIncidente: { "value": 1, "label": LanguageTranslate().moduloRDI.form.TipoIncidentePersonal, "dataComplete": { "IdTipoIncidente": 1, "Incidente": "PP(Pers.Propio)", "Abreviatura": "PP" } },
    CondicionInsegura: { "value": 0, "label": "No", dataComplete: {} },
    VehiculoAfectado: { "value": 0, "label": "No", dataComplete: {} },
    ParalizacionTrabajo: { "value": 0, "label": "No", dataComplete: {} },
    Direccion: '',
    Subcontrata: null as any,
    MedidasPropuestas: "",
    UsuarioMP: null as any,
    Obra: "",
    Provincia: "",
    Placa: "",
    DescripcionSolucion: "",
    altoRiesgo: false
}