import { EntityTipoPregunta } from "../../../shared/Domain/Catalogos/EntityTipoPregunta";
import { EntityFormatos } from "../../../shared/Domain/CatalogosV2/EntityFormatos";
import { EntityAutochequeoItems, EntityAutochequeoItemsTipologia } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityAutochequeoTipologia } from "../../../shared/Domain/EntityAutochequeoTipologia";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";
import { LanguageApp } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "../Infaestructure/AdapterConfigure";

export class useCaseLoadQuestionsCP_V2 {
    private repository: RepositoryMain;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.languageDefault = LanguageApp();
    }

    public async exec(OT:EntityOTs, Tipologias: EntityAutochequeoTipologia[], IdActividad: number, isModePreview?: boolean): Promise<EntityLoadQuestion> {
        const [Formatos, hasQuestionInCCP] = await Promise.all([
            this.getFormatos(OT.Delegacion.IdDelegacion, OT.Pais.IdPais, IdActividad ?? OT.Actividad.IdActividad, Tipologias),
            this.hasQuestionInCCP(OT.Delegacion.IdDelegacion, OT.Pais.IdPais, IdActividad ?? OT.Actividad.IdActividad)
        ]);

        // Juntar los items
        const newAutochequeoTitulos = ([] as EntityAutochequeoTitulos[]).concat([], Formatos.titulos);
        const newAutochequeoItems = ([] as EntityAutochequeoItems[]).concat([], Formatos.preguntas)
        const availableTitles = this.filterAvailableTitles(newAutochequeoTitulos, newAutochequeoItems);

        return ({
            AutoChequeoTipologia: Formatos.tipologias,
            AutochequeoTitulos: availableTitles,
            AutochequeoItems: newAutochequeoItems.sort((itemA, itemB) => itemA.IdRegistro - itemB.IdRegistro),
            cierreControlPreventivoHasQuestion: hasQuestionInCCP
        });
    }

    private async getPreguntaLA() {
        const response = (await this.repository.getPreguntasLA()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.tipoPregunta.codigo));
        return response;
    }

    private async getTipoPregunta() {
        const codeLanguage = LanguageApp();
        const response = (await this.repository.getTipoPreguntas()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.codigo));
        return response.map((row: EntityTipoPregunta, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: "",
                Codigo: "",
                IdActividad: 0
            },
            CodigoTitulo: row.codigo,
            Delegacion: {
                Codigo: "",
                Delegacion: "",
                IdDelegacion: 0,
            },
            Estado: {
                Estado: "Activo",
                IdEstado: 1,
            },
            IdTitulo: row.IdTipoPreguntas,
            Momento: {
                IdMomento: 0,
                Momento: ""
            },
            Orden: index + 1,
            Pais: {
                IdPais: 0,
                Pais: ""
            },
            Requerimientos: {
                Fotografia: 0,
                Observacion: 0
            },
            Template: "",
            Tipologia: {
                CodigoTipologia: "",
                IdTipologia: 0,
                TipologíaTrabajo: ""
            },
            Titulo: (row.iteminIdioma.find(row => row.codigo === codeLanguage)?.nombre || '').toUpperCase(),
        }));
    }

    private async hasQuestionInCCP(IdDelegacion: number, IdPais: number, IdActividad: number): Promise<boolean> {
        return false;
    }

    private filterAvailableTitles(AutochequeoTitulos: EntityAutochequeoTitulos[], AutochequeoItems: EntityAutochequeoItems[] ) {
        return AutochequeoTitulos.filter(row => {
            let listQuestionShow = AutochequeoItems.filter(item =>
                JSON.parse(item.TipologiaArray).some((tpl: EntityAutochequeoItemsTipologia) => tpl.Titulo.IdTitulo === row?.IdTitulo && tpl.Titulo.CodigoTitulo === row.CodigoTitulo)
            );

            return listQuestionShow.length > 0;
        })
    }

    private async getFormatos(IdDelegacion: number, IdPais: number, IdActividad: number, Tipologias: EntityAutochequeoTipologia[]) {
        let dataActividad = {
            _id: '',
            Actividad: '',
            Codigo: '',
            IdActividad: 0
        };

        let dataDelegacion = {
            _id: '',
            IdDelegacion: 0,
            Codigo: '',
            Delegacion: '',
        }

        let dataPais = {
            _id: '',
            IdPais: 0,
            Codigo: '',
            Pais: '',
        }

        let response = (await this.repository.getFormatosV2()).filter(row =>
            row.actividadInfo.IdActividad === IdActividad &&
            row.paisInfo.IdPais === IdPais
        ).reduce((prev, current) => {
            dataActividad = current.actividadInfo;
            dataDelegacion = current.delegacionInfo;
            dataPais = current.paisInfo;

            return [
                ...prev,
                ...current.familias.filter(row => row.momento.Codigo === AdapterKeyGeneral.v2.codigoMomentoControlPreventivo && row.tipologias.length > 0)
            ]
        }, [] as EntityFormatos["familias"]).sort((rowA, rowB) => (rowA?.orden ?? 1) - (rowB?.orden ?? 1));

        // Formatear tipología
        const tipologias: EntityAutochequeoTipologia[] = response.flatMap(familia => familia.tipologias.filter(row => Tipologias.find(tpl => tpl._id === row._id)).map((row, index) => ({
            _id: row._id,
            CodigoTipologia: row._id,
            FamiliaTipologia: {
                Familia: familia.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
                IdFamiliaTipologia: familia._id as any
            },
            IdActividad: IdActividad,
            IdDelegacion: IdDelegacion,
            IdPais: IdPais,
            IdTipologia: row._id as any,
            InformacionTipologia: '',
            Momento: {
                IdMomento: familia.momento._id as any,
                Momento: 'Lista Autochequeo',
            },
            Orden: index + 1,
            RiesgosEspeciales: row.esCritico ? 1 : 0,
            TipologiaTrabajo: ''
        } as EntityAutochequeoTipologia)))

        const titulos: EntityAutochequeoTitulos[] = response.flatMap((familia, indexFamilia) => familia.tipologias.filter(row => (row.titulos.length > 0) && Tipologias.find(tpl => tpl._id === row._id)).flatMap((tipologia) => tipologia.titulos.map((row, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: dataActividad.Actividad,
                Codigo: dataActividad.Codigo,
                IdActividad: dataActividad.IdActividad
            },
            CodigoTitulo: row._id,
            Delegacion: {
                Codigo: dataDelegacion.Codigo,
                Delegacion: dataDelegacion.Delegacion,
                IdDelegacion: dataDelegacion.IdDelegacion
            },
            Estado: {
                Estado: 'Activo',
                IdEstado: 1
            },
            IdTitulo: row._id as any,
            Momento: {
                IdMomento: familia.momento._id as any,
                Momento: 'Lista Autochequeo',
            },
            Orden: (row.orden ?? (index + 1)) + ((indexFamilia + 1) * 100),
            Pais: {
                IdPais: dataPais.IdPais,
                Pais: dataPais.Pais
            },
            Requerimientos: {
                Fotografia: row.requiereFoto ? 1 : 0,
                Observacion: row.requiereObservacion ? 1 : 0
            },
            Template: '',
            Tipologia: {
                CodigoTipologia: tipologia._id,
                IdTipologia: tipologia._id as any,
                TipologíaTrabajo: familia.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? familia.iteminIdioma[0]?.nombre ?? '',
            },
            Titulo: row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? row.iteminIdioma[0]?.nombre ?? '',
            adittionalIdTitle: [row._id],
        }) as EntityAutochequeoTitulos))).sort((rowA, rowB) => rowA.Orden - rowB.Orden);

        const preguntas: EntityAutochequeoItems[] = response.flatMap(familia => familia.tipologias.filter(row => Tipologias.find(tpl => tpl._id === row._id)).flatMap((tipologia) => tipologia.titulos.flatMap(titulo => titulo.preguntas.map(preguntas => {
            const findTitulo = titulos.find(title => title._id === preguntas._idTitulo);

            return {
                _id: preguntas._id,
                Disponibilidad: JSON.stringify(preguntas.disponibilidad.filter(row => !row.ocultar).map(row => ({
                    "Respuesta": row.item.toUpperCase(),
                    "Estados": row.mostrarEstados,
                    "Observacion": row.ingresarObservacion,
                    "EnviarCorreo": row.enviarCorreo,
                    "CharlaPreTarea": row.charlaPreTarea,
                    "DevolverOrden": row.orden,
                    "NombreFoto": "",
                    "CantidadFotos": row.cantidadFotos,
                    "IdHijo": 0
                }))),
                Estados: JSON.stringify(preguntas.estados.filter(row => !row.ocultar).map(row => ({
                    "Respuesta": row.item,
                    "Observacion": row.observacion,
                    "EnviarCorreo": row.enviarCorreo,
                    "NombreFoto": "",
                    "CantidadFotos": row.cantidadFotos,
                    "DevolverOrden": row.orden,
                    "IdHijo": 0
                }))),
                Help: preguntas.iteminIdioma.find(row => row.codigo === this.languageDefault)?.help ?? '',
                IdActividad: IdActividad,
                IdDelegacion: IdDelegacion,
                IdPais: IdPais,
                IdRegistro: preguntas._id as any,
                Item: preguntas.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre ?? preguntas.iteminIdioma[0]?.nombre ?? '',
                Momento: {
                    IdMomento: familia.momento._id as any,
                    Momento: 'Lista Autochequeo',
                },
                OpcionesCierreCP: "",
                Orden: preguntas.orden,
                TipologiaArray: JSON.stringify([
                    {
                        "IdTipologia": tipologia?._id ?? '',
                        "CodigoTipologia": tipologia?._id ?? '',
                        "Orden": 1,
                        "Titulo": {
                            "IdTitulo": findTitulo?._id,
                            "Titulo": findTitulo?.Titulo, 
                            "CodigoTitulo": findTitulo?._id
                        }
                    }
                ]),
                VisibilidadPregunta: 1,
            } as EntityAutochequeoItems }
        ))))

        return { tipologias, titulos, preguntas };
    }
}