// Library
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import { Dispatch } from 'redux';
import * as Yup from 'yup';

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterChronometer } from '../../../shared/Infraestructure/AdapterChronometer';
import { changePreference } from '../../../shared/Infraestructure/SliceAuthentication';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { AdapterCheckiOS } from '../../../shared/Infraestructure/AdapterCheckiOS';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseList } from '../Application/UseCaseList';
import { AdapterConfigure } from "./AdapterConfigure";

// Domain
import { EntityConfigModalPDF, EntityFormatChronometer, EntityInitialData, EntityRequestRevision, initEntityConfigModalPDF } from '../Domain/Utilts';
import { EntityConfigAudio, initEntityConfigAudio } from '../Domain/EntityConfigAudio';
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import { EntitySelectBase } from '../../../shared/Domain/EntitySelectBase';
import { EntityDelegacion } from '../../../shared/Domain/EntityDelegacion';
import { EntityPersonal } from '../../../shared/Domain/EntityPersonal';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { AdapterLoadMaster } from '../../../shared/Infraestructure/AdapterLoadMaster';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { AdapterCheckConnection } from '../../../shared/Infraestructure/AdapterCheckConnection';

let audioInput: HTMLAudioElement | null = null; 
let adapterChronometer: AdapterChronometer | null = null;
let idAudio: number = 0;
// let currentPagination: number = 0;

export const Controller = () => {
    const { generico: { websocket, dbLocal, countProcess }, auth: { user, preferencia, permisoVariables } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const [data, setData] = useState<EntityAutochequeo[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setModal] = useState(false);
    const [configList] = useState<{ loading: boolean, completeLoad: boolean }>({ loading: false, completeLoad: true })
    const languageTranslate = LanguageTranslate();

    const [configAudio, setConfigAudio] = useState<EntityConfigAudio>(initEntityConfigAudio);
    const [configDataView, setConfigDataView] = useState<EntityInitialData>({ delegacion: [], personal: [] });
    const [configModalPDF, setConfigModalPDF] = useState<EntityConfigModalPDF>(initEntityConfigModalPDF);
    const [playTimeAudio, setPlayTimeAudio] = useState<EntityFormatChronometer>({ hrs: 0, min: 0, sec: 0 });
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryAdapterLoader: AdapterLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const formRegistro = useFormik({
        initialValues: {
            group: false,
            active: true
        } as { FechaInicial: string; FechaFinal: string; Personal: EntitySelectBase<EntityPersonal>; Delegacion: EntitySelectBase<EntityDelegacion>; group: boolean; active: boolean },
        validationSchema: Yup.object({
            FechaInicial: Yup.string().required(languageTranslate.moduloAutoChequeo.list.validate.formFilter.fechaInicial).nullable(),
            FechaFinal: Yup.string().required(languageTranslate.moduloAutoChequeo.list.validate.formFilter.fechaFinal).nullable(),
            Personal: Yup.object().when(['group'], { is: (group: boolean) => (!group), then: Yup.object().required('Ingrese una Personal').nullable() }).nullable(),
            Delegacion: Yup.object().when(['group'], { is: (group: boolean) => (group), then: Yup.object().required('Ingrese una Delegación').nullable() }).nullable(),
            group: Yup.boolean().nullable(),
            active: Yup.boolean().nullable(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const init = async () => {
        try {
            // currentPagination = 0;
            dispatch(changeSaludo(false));
            setLoading(true);
            autoReload();
            const initialData = await repository.initialData();
            switch (user.DatosRol.Codigo) {
                case '02AP': // Administrador País
                    initialData.personal = initialData.personal.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.IdPais));
                    initialData.delegacion = initialData.delegacion.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.Pais.IdPais));
                    break;
                case '03AD': // Administrador Delegación
                    initialData.personal = initialData.personal.filter(row => permisoVariables.arrIdDelegaciones.includes(row.dataComplete.IdDelegacion));
                    initialData.delegacion = initialData.delegacion.filter(row => permisoVariables.arrIdDelegaciones.includes(row.dataComplete.IdDelegacion));
                    break;
            }
            setConfigDataView(() => ({...initialData}));
            adapterChronometer = new AdapterChronometer(setPlayTimeAudio);
        } catch (error) {
            window.location.reload();
        }
    };

    const autoReload = async () => {
        const informationDataInitial = repositoryAdapterLoader.initServicesCalled();
        const fecha = AdapterGenerico.convertDateToString(informationDataInitial.Autochequeo.date as Date, 1);

        const params = {
            "Filtros": [
                {
                    "$match": {
                        "$and": [{
                            "Pais.IdPais": { "$in": user.Pais.map((pais: any) => pais.IdPais) },
                            "Tecnico.Identificacion": { "$in": [user.Identificacion] },
                        }]
                    }
                },
                {
                    "$match": {
                        "$or": [
                            { "Procesos.Eliminar.Fecha": { $gt: fecha } },
                            { "Procesos.Modificar.Fecha": { $gt: fecha } },
                            { "Procesos.Registrar.Fecha": { $gt: fecha } },
                        ]
                    }
                }
            ]
        }

        if(new AdapterCheckConnection().isStable()) { 
            try {
                dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
                await repository.getListGeneric(params);
                informationDataInitial.Autochequeo.date = new Date();
                AdapterStorage.set('servicesCalleds', informationDataInitial);
            } catch (err) {
                let error: ErrorCostume = new ErrorCostume((err as Error).message);
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
            }
        }

        await reloadList();
        setLoading(false);
        dispatch(removeLoading());
    }

    const reloadList = async () => {
        // currentPagination = 0;
        const response: EntityAutochequeo[] = await repository.customGetListAutochequeo(user.IdUsuario === 0 ? 'guest' : 'user');
        // if (response.length < AdapterConfigure.COUNT_MAX_LIST) setConfigList((prev) => ({ ...prev, completeLoad: true }))

        let orderData = await handleOrder(response);
        if (orderData.length > AdapterConfigure.COUNT_MAX_LIST) {
           orderData = orderData.slice(0, AdapterConfigure.COUNT_MAX_LIST)
        }
        setData(orderData);
    }

    const paginationList = async () => {
        /*currentPagination+= 1;
        setConfigList((prev) => ({ ...prev, loading: true }))
        dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

        const response: EntityAutochequeo[] = await repository.customGetListAutochequeo(user.IdUsuario === 0 ? 'guest' : 'user', currentPagination);
        if (response.length < AdapterConfigure.COUNT_MAX_LIST) setConfigList((prev) => ({ ...prev, completeLoad: true }))
        const orderData = await handleOrder([...data, ...response]);
        setData(orderData);
        dispatch(removeLoading());
        setConfigList((prev) => ({ ...prev, loading: false }))*/
    }

    const loadData = async () => {
        const _31DaysAgo = new Date();
        _31DaysAgo.setDate(_31DaysAgo.getDate() - 31);

        const params = {
            "Filtros": [
                {
                    "$match": {
                        "$and": [{
                            // "Pais.IdPais": { "$in": user.Pais.map((pais: any) => pais.IdPais) },
                            "Tecnico.Identificacion": { "$in": [user.Identificacion] },
                            "Estado.IdEstado": { "$nin": [99] },
                            "Procesos.Registrar.IdFecha": { "$gte": AdapterGenerico.getIdFechaGlobal(_31DaysAgo)  }
                        }]
                    }
                }
            ]
        }
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const response = await (new UseCaseList(repository).exec(params));
            if (response === null) return;

            updatePreferencePersonal(response);
            const orderData = await handleOrder(response);
            setData(() => ([...orderData]));
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const updatePreferencePersonal = (list: EntityAutochequeo[]) => {
        const itemsAUT = list.filter(row => row.Momento.IdMomento === 1 && row.Personal.length > 0);
        const lastItem = itemsAUT[itemsAUT.length - 1];
        if (!lastItem) return;

        const _preferencia = {
            ...preferencia,
            personal: (AdapterGenerico.formatoPersonalizadoSelect(lastItem.Personal, 'Identificacion', ['Nombres', 'Apellidos']) as [])
        }

        dispatch(changePreference(_preferencia))
    }

    const handleOrder = async (data: EntityAutochequeo[]): Promise<EntityAutochequeo[]> => {
        if (!Array.isArray(data)) return [];
        // función para asignar a los ítems con notificaciones
        const allNotification = await repository.getListNotificationPending();
        return data.map(row => ({
            ...row,
            Puntuacion: row.Puntuacion || [],
            Estado: row.Estado || { Estado: "", IdEstado: 0 },
            Momento: row.Momento || { IdMomento: 0, Momento: "" },
            isHasNotification: allNotification.some(_row => _row.modulo.some(x => x._id === row._id)),
            Id: row.Id || Date.now()
        })).sort((a, b) =>
            Number(a.Procesos?.Registrar?.Fecha ? new Date(a.Procesos.Registrar.Fecha) : new Date()) - Number(new Date(b.Procesos?.Registrar?.Fecha ? new Date(b.Procesos.Registrar.Fecha) : new Date()))).reverse();
    }

    // Funcionts Modal Search

    const onChange = (name: string, value: any) => {
        formRegistro.setFieldValue(name, value);
    };

    const onSetModal = async (valor: boolean) => {
        if (valor) {
            const { currentDayFormatted } = AdapterGenerico.getIntervalCurrentMonth();
            onChange('FechaInicial', currentDayFormatted);
            onChange('FechaFinal', currentDayFormatted)
        }
        setModal(valor);
    }

    const onSearchSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formRegistro.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formRegistro.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            setModal(false);
            let FechaInicial = parseInt(formRegistro.values["FechaInicial"].replace(new RegExp("-", "g"), ""));
            let FechaFinal = parseInt(formRegistro.values["FechaFinal"].replace(new RegExp("-", "g"), ""));

            const params = {
                "Filtros": [
                    {
                        "$match": {
                            "$and": [{
                                "FechaRegistro.IdFecha": { '$gte': FechaInicial, '$lte': FechaFinal },
                                ...(
                                    formRegistro.values.group ? 
                                        { "IdDelegacion": { "$in": [formRegistro.values.Delegacion.dataComplete.IdDelegacion] } }
                                    :
                                        { "Tecnico.Identificacion": { "$in": [formRegistro.values.Personal.dataComplete.Identificacion] } }
                                ),
                                ...(
                                    formRegistro.values.active ?
                                        { "Estado.IdEstado": { "$in": [1] }, "EstadoCP.IdEstado": { "$nin": [1] } }
                                        :
                                        { "Estado.IdEstado": { "$nin": [99] } }
                                )
                            }]
                        }
                    },
                    {
                        $limit: 200
                    }
                ]
            }

            try {
                dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
                const response = await (new UseCaseList(repository).exec(params));
                if (response === null) return;
                const orderData = await handleOrder(response);
                setData(orderData);
                formRegistro.resetForm();
            } catch (err) {
                let error: ErrorCostume = new ErrorCostume((err as Error).message);
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
            } finally {
                dispatch(removeLoading());

            }

        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    // Descargar PDF

    const onDownloadPdf = async (params: any) => {
        
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            let variablesDescarga = params.Id + "||" + params.IdControlPreventivo + "||" + params.Tecnico;
            let variablesDescarga_b64 = btoa(unescape(encodeURIComponent(variablesDescarga)));//atob(variablesDescarga)
            let path = process.env.REACT_APP_URL_MASTER + "" + AdapterConfigure.CONTROLPREVENTIVO_DONWLOAD_PDF + "/" + variablesDescarga_b64;

            const responseBlob = await repository.DownloadFile(path);
            if (!responseBlob) return;

            const urlTemp = (window.URL ? URL : webkitURL).createObjectURL(responseBlob)
            let alink = document.createElement('a');
            alink.href = urlTemp;
            alink.download = params.Id + '.pdf';
            alink.click();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // Functions audio

    const onSetReproducirAudio = async (params: any) => {
        try {
            // Si le dio a pausa
            if (!params.play) {
                audioInput?.pause();
                adapterChronometer?.pause();
                setConfigAudio((prev) => ({...prev, isReproduce: false}))
                return;
            }

            // Si contiene el mismo audio
            if (params.idAudio === configAudio.idAudio) {
                onPlayAudio();
                return;
            }

            // Plantilla del audio
            let payload: EntityConfigAudio = {
                idAudio: params.idAudio,
                isReproduce: AdapterCheckiOS.isiOS() ? false : params.play
            };

            audioInput?.pause();
            adapterChronometer?.reset();
            // let variablesDescarga = "CP||" + params.IdFecha + "||" + params.Archivos
            let variablesDescarga = `${params.IdFecha}||${params.Archivos}`;
            let variablesDescarga_b64 = btoa(unescape(encodeURIComponent(variablesDescarga)));
            let path = process.env.REACT_APP_URL_MASTER_NEW_BACKEND + "" + AdapterConfigure.CONTROLPREVENTIVO_DONWLOAD + variablesDescarga_b64;

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const responseBlob = params.audioTemp ? params.audioTemp : await repository.DownloadAudio(path);

            if (responseBlob) {
                audioInput = new Audio()
                audioInput.preload = "metadata";
                audioInput.src = (window.URL ? URL : webkitURL).createObjectURL(responseBlob);
                audioInput.onended = () => {
                    adapterChronometer?.reset();
                    setConfigAudio((prev) => ({...prev, isReproduce: false}));
                    onCompleteRevision('audio');
                }
                audioInput.load();
            }
            
            idAudio = payload.idAudio;
            setConfigAudio(() => ({...payload}));
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }

        if (!AdapterCheckiOS.isiOS()) {
            onPlayAudio();
        }
    }

    const onPlayAudio = () => {
        if (!audioInput) return;
        try {
            audioInput?.play();
            adapterChronometer?.start();
            setConfigAudio((prev) => ({...prev, isReproduce: true}));
        } catch(error: any) {
        }
    }

    // Functions redirect
    const redirectGestion = (_id: string) => navigate(`${AdapterConfigure.REDIRECT_CIERRECP}/${_id}`);
    const redirectCharlaPreTarea = () => navigate(`${AdapterConfigure.REDIRECT_CPT}`, { replace: true })
    const redirectPreview = (_id: string) => navigate(`${AdapterConfigure.REDIRECT_PREVIEW}/${_id}`);

    // Configuración Modal PDF
    const openModalPDF = async (params: any) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            let variablesDescarga = params.Id + "||" + params.IdControlPreventivo + "||" + params.Tecnico;
            let variablesDescarga_b64 = btoa(unescape(encodeURIComponent(variablesDescarga)));//atob(variablesDescarga)
            let path = process.env.REACT_APP_URL_MASTER + "" + AdapterConfigure.CONTROLPREVENTIVO_DONWLOAD_PDF + "/" + variablesDescarga_b64;

            const responseBlob = await repository.DownloadFile(path);
            if (!responseBlob) return;

            const urlTemp = (window.URL ? URL : webkitURL).createObjectURL(responseBlob);
            setConfigModalPDF((prev) => ({
                ...prev,
                show: true,
                src: urlTemp,
                title: `${languageTranslate.textoModoVisualizar} PDF`,
                name: params.Id + '.pdf',
                idPDF: params.Id
            }))

        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const closeModalPDF = () => {
        setConfigModalPDF(() => ({
            show: false,
            src: "",
            title: "",
            name: "",
            idPDF: 0
        }))
    }

    const downloadCurrentPDF = async () => {
        let alink = document.createElement('a');
        alink.href = configModalPDF.src;
        alink.download = configModalPDF.name;
        alink.click();
    }

    const onCompleteRevision = async (type: 'audio' | 'pdf') => {
        try {
            let currentRegister = data.find(row => row.Id === (type === 'audio' ? idAudio : configModalPDF.idPDF));
            if (!currentRegister) return;
            if (currentRegister.Tecnico.Identificacion === user.Identificacion) return;
            if (currentRegister.Revision?.Pdf?.RevisionPdf === 100 && type === 'pdf') return;
            if (currentRegister.Revision?.Audio?.RevisionAudio === 100 && type === 'audio') return;

            let personalRevision = {
                Apellidos: `${user.ApellidoPaterno} ${user.ApellidoMaterno}`,
                Codigo: user.DatosPersonal[0].Codigo || "",
                Identificacion: user.Identificacion,
                IdPersonal: user.IdUsuario,
                Nombres: user.Nombres
            }

            let payload: EntityRequestRevision = {
                Id: currentRegister.Id,
                Archivos: currentRegister.Archivos,
                Tecnico: currentRegister.Tecnico as any,
                Bitacora: {
                    Accion: 'Agregando Revisión APP',
                    Datos: {},
                    DatosUsuario: {
                        Usuario: user.Usuario,
                        ApellidoMaterno: user.ApellidoMaterno,
                        ApellidoPaterno: user.ApellidoPaterno,
                        Identificacion: user.Identificacion,
                        IdUsuario: user.IdUsuario,
                        Nombres: user.Nombres
                    },
                    Fecha: {}
                },
                IdControlPreventivo: currentRegister.IdControlPreventivo || 0,
                Procesos: {
                    ...currentRegister.Procesos as any,
                    Modificar: {
                        Fecha: "",
                        DatosUsuario: {
                            Nombres: user.Nombres,
                            ApellidoMaterno: user.ApellidoMaterno,
                            ApellidoPaterno: user.ApellidoPaterno,
                        },
                        IdFecha: 0,
                        IdUsuario: user.IdUsuario,
                        Observacion: "Agregando Revisión APP",
                        Usuario: user.Usuario
                    }
                },
                Revision: (currentRegister.Revision as any) || {}
            }

            if (type === 'audio') {
                Object.assign(payload.Revision, {
                    Audio: {
                        PersonalRevision: personalRevision,
                        RevisionAudio: 100
                    }
                })
            }
            if (type === 'pdf') {
                Object.assign(payload.Revision, {
                    Pdf: {
                        PersonalRevision: personalRevision,
                        RevisionPdf: 100
                    }
                })
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            await repository.saveRevision(payload, currentRegister);
            reloadList();

        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return {
        init,
        data,
        loadData,
        loading,
        onChange,
        formRegistro,
        onSearchSubmit,
        showModal,
        onSetModal,
        onDownloadPdf,
        onSetReproducirAudio,
        redirectGestion,
        redirectPreview,
        countProcess,
        user,
        redirectCharlaPreTarea,
        configDataView,
        reloadList,

        configModalPDF,
        openModalPDF,
        closeModalPDF,
        downloadCurrentPDF,
        onCompleteRevision,

        configAudio,
        onPlayAudio,
        playTimeAudio,
        paginationList,
        configList,
    }
}