import { useState } from 'react';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import './ViewItem.scss';
import { AdapterConfigure } from '../Infaestructure/AdapterConfigure';
import { AdapterCheckiOS } from '../../../shared/Infraestructure/AdapterCheckiOS';
import { EntityFormatChronometer } from '../Domain/Utilts';
interface IViewItem {
    IdAudio: number;
    isReproduce: boolean;
    data: EntityAutochequeo;
    subData: EntityAutochequeo[];
    openModalPDF: (params: any) => Promise<void>
    onSetReproducirAudio: Function;
    onDownloadPdf: Function;
    onPlay: Function;
    redirectGestion: Function;
    redirectPreview: (_id: string) => void;
    openModalListaComentarios: Function;
    playTimeAudio: EntityFormatChronometer;
}

export const ViewItem = (props: IViewItem) => {
    const [isTouch, setTouch] = useState(false);
    const languageTranslate = LanguageTranslate();
    const isiOS = AdapterCheckiOS.isiOS();
    const timeString = `${props.playTimeAudio.hrs.toString().padStart(2, '0')}:${props.playTimeAudio.min.toString().padStart(2, '0')}:${props.playTimeAudio.sec.toString().padStart(2, '0')}`;
    const { data, subData } = props;
    const showPendingCCP = data.Momento.IdMomento === 3 && data?.EstadoCP?.IdEstado === 10;
    const currentSubData = subData.filter(row => row.IdControlPreventivo === data.Id || row.IdControlPreventivo === data._id);

    return (
        <>

            <div className="row pb-3 ViewAutochequeoItem">

                {/** ITEM PRINCIPAL */}
                <div className="col-12">
                    <div className={`shadow-lg group-content-item position-relative ${data?.Estado.IdEstado === -1 ? 'pending' : (
                        showPendingCCP ? 'pending-cp' : ''
                    )}`}>

                        <div className="col-12">
                            <div className="row" style={{ margin: '1px' }}>

                                <div className="col-7">
                                    <span style={{ fontSize: '13px', textTransform: 'uppercase' }}>{
                                        data.Momento.IdMomento === 1 ?
                                            languageTranslate.moduloAutoChequeo.list.TituloListaAutochequeo :
                                            (
                                                data.Momento.IdMomento === 2 ?
                                                    languageTranslate.moduloAutoChequeo.list.TituloListaControlPreventivo
                                                :
                                                    languageTranslate.moduloAutoChequeo.form.navBarTextCharlaPretarea
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        {
                            data.isHasNotification ? 
                            <div className='position-absolute' style={{ right:'1.6rem', top: 10 }}>
                                <i className="fa-solid fa-envelope" style={{ color: (data.Puntuacion || []).length > 0 ? AdapterConfigure.COLOR_CALIFICACION[(data.Puntuacion || [])[(data.Puntuacion?.length || 0) - 1].Puntuacion] : "#929292" }}></i>
                            </div>
                            : null
                        }

                        <div className="row" style={{ margin: '1px', padding: '8px 0' }}>

                            <div className="col-10">
                                <div>
                                    <span className="text-danger">{languageTranslate.textoNro} {data.Id}</span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloAutoChequeo.list.Estado}:&nbsp;</span>
                                    <span className='text-muted'>
                                        {
                                        data.Estado.IdEstado === -1 ?
                                        'Pendiente'
                                        :
                                        (data.EstadoCP?.Estado && data.Momento.IdMomento === 3) ? (data.EstadoCP.IdEstado === 10 ? languageTranslate.moduloAutoChequeo.list.textoCPPendiente : languageTranslate.moduloAutoChequeo.list.textoCPCerrado) : (data.Estado?.IdEstado === 1 ? languageTranslate.moduloAutoChequeo.list.EstadoItems.EstadoActivo : languageTranslate.moduloAutoChequeo.list.EstadoItems.EstadoInactivo) }
                                    </span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloAutoChequeo.list.Resultado}: </span><span className='text-muted'>{ (currentSubData.some(row => row.ResultadoPreguntas !== 0) || data.ResultadoPreguntas !== 0) ? languageTranslate.moduloAutoChequeo.list.ResultadoItems.ResultadoIncorrecto : languageTranslate.moduloAutoChequeo.list.ResultadoItems.ResultadoCorrecto} </span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloAutoChequeo.list.Autor}: </span>
                                    <span className='text-muted'>{data.Tecnico.Nombres} {data.Tecnico.Apellidos}</span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloAutoChequeo.list.FechaRegistro}: </span>
                                    {data?.FechaRegistro?.FechaRegistro && <span className='text-muted'> {AdapterGenerico.convertDateToString(new Date(data.FechaRegistro.FechaRegistro), 2)}</span>}
                                </div>
                                <div>
                                    {
                                        data.Momento.IdMomento === 3 ?
                                        <>
                                            <span>{languageTranslate.moduloAutoChequeo.list.textAudio}: </span>
                                            <span className='text-muted'>{data.Revision?.Audio?.RevisionAudio || ' - '}%</span>
                                            <span className='mx-4 text-muted'>|</span>
                                        </>
                                        : null
                                    }
                                    <span>{languageTranslate.moduloAutoChequeo.list.textPDF}: </span>
                                    <span className='text-muted'>{data.Revision?.Pdf?.RevisionPdf || ' - '}%</span>
                                </div>

                            </div>

                            <div className='col-2 d-flex flex-column options' style={{ alignItems: 'center', gap: 5 }}>
                                {
                                    showPendingCCP && (
                                        <>
                                            <i onClick={() => props.redirectGestion(data._id)} className="icon-custom mt-2 fa-solid fa-share-from-square" style={{ fontSize: '18px' }} />
                                        </>
                                    )
                                }
                                {
                                    <>
                                        <i onClick={() => props.redirectPreview(data._id + "")} className="icon-custom mt-2 fa-solid fa-eye" style={{ fontSize: '18px' }} />

                                        {
                                            data.Estado.IdEstado !== -1 ?
                                                <i onClick={() => {
                                                    let params = {
                                                        IdControlPreventivo: data.IdControlPreventivo,
                                                        Tecnico: data.Tecnico.Identificacion,
                                                        Id: data.Id
                                                    }
                                                    props.openModalPDF(params);
                                                }} className="icon-custom mt-2 fa-solid fa-file-pdf" style={{ fontSize: '18px' }} />
                                            : null
                                        }


                                    </>
                                }
                                {
                                    (data.Archivos !== "") && (
                                        <>
                                            {
                                                isiOS && props.IdAudio !== data.Id ?
                                                    <>
                                                        { /** iOS */ }
                                                        <i
                                                            onClick={() => {
                                                                let params = {
                                                                    play: true,
                                                                    idAudio: data.Id,
                                                                    IdFecha: data.Procesos.Registrar.IdFecha,
                                                                    Archivos: data.Archivos,
                                                                    audioTemp: data.dataSend?.Files.find(row => row.name.includes('.mp3'))?.value
                                                                }
                                                                props.onSetReproducirAudio(params);
                                                            }}
                                                            className="icon-custom mt-2 fa-solid fa-cloud-arrow-down" style={{ fontSize: '18px' }}
                                                        />
                                                        <span style={{ fontSize: '0.8rem' }}>{data.TiempoAudio}</span>
                                                    </>
                                                :
                                                <>
                                                    { /** GLOBAL PARA ANDROID */ }
                                                    {
                                                        props.isReproduce && props.IdAudio === data.Id ?
                                                        <>
                                                            <i
                                                                onClick={() => {
                                                                    let params = {
                                                                        play: false,
                                                                        idAudio: data.Id,
                                                                        IdFecha: data.Procesos.Registrar.IdFecha,
                                                                        Archivos: data.Archivos,
                                                                        audioTemp: data.dataSend?.Files.find(row => row.name.includes('.mp3'))?.value
                                                                    } 
                                                                    props.onSetReproducirAudio(params);
                                                                }}
                                                                className="icon-custom mt-2 fa-solid fa-circle-pause" style={{ fontSize: '18px' }}
                                                            />
                                                            { props.IdAudio === data.Id ? <span style={{ color: '#0f46ff', borderBottom: '1px solid #0f46ff', marginBottom: '-6px' }}>{timeString}</span> : null }
                                                            <span style={{ fontSize: '0.8rem' }}>{data.TiempoAudio}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <i
                                                                onClick={() => {
                                                                    let params = {
                                                                        play: true,
                                                                        idAudio: data.Id,
                                                                        IdFecha: data.Procesos.Registrar.IdFecha,
                                                                        Archivos: data.Archivos,
                                                                        audioTemp: data.dataSend?.Files.find(row => row.name.includes('.mp3'))?.value
                                                                    };
                                                                    isiOS ? props.onPlay() : props.onSetReproducirAudio(params);
                                                                }}
                                                                className="icon-custom mt-2 fa-solid fa-circle-play" style={{ fontSize: '18px' }}
                                                            />
                                                            { props.IdAudio === data.Id ? <span style={{ color: '#0f46ff', borderBottom: '1px solid #0f46ff', marginBottom: '-6px' }}>{timeString}</span> : null }
                                                            <span style={{ fontSize: '0.8rem' }}>{data.TiempoAudio}</span>
                                                        </>

                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                }
                            </div>

                        </div>

                        <div className='col-12 d-flex gap-1 px-2 pb-2' style={{ placeContent: 'end' }}>
                            
                            <div className='icon-circle-base' style={{ background: (data.Puntuacion || []).length > 0 ? AdapterConfigure.COLOR_CALIFICACION[(data.Puntuacion || [])[(data.Puntuacion?.length || 0) - 1].Puntuacion] : "#929292" }} onClick={() => props.openModalListaComentarios(data)}>
                                <i className="fa-sharp fa-solid fa-star" style={{ fontSize: 12, color: 'white' }}></i>
                            </div>

                            {
                                /*
                                data.FeedBack ?
                                    <div className='icon-circle-base' style={{ background: '#5e4f4f8f' }}>
                                        <i className="fa-regular fa-comment-dots" style={{ fontSize: 12, color: 'white' }}></i>
                                    </div>
                                : null */
                            }
                        </div>
                    </div>
                </div>


                {/** FLECHA PARA MOSTRAR O OCULTAR LOS SUBITEMS */}
                {
                    subData.filter(c => c.IdControlPreventivo === data.Id || c.IdControlPreventivo === data._id).length !== 0 && (
                        <div className="col-12 " >
                            <div className="col-12 d-flex flex-column icoVerCP" style={{ alignItems: 'center' }} onClick={() => setTouch(!isTouch)}>
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                        </div>
                    )
                }


                {/** MOSTRAR SUB ITEMS */}
                {
                    isTouch && (

                        <div className="col-12 " >
                            <div className={`shadow-lg group-content-item`}>
                                <>
                                    {
                                        subData.filter(c => c.IdControlPreventivo === data.Id || c.IdControlPreventivo === data._id).map((item, key) =>
                                            <>
                                                <div className={'col-12 subItems correctoCP' /*item?.EstadoCP?.IdEstado === 1 || item?.EstadoCP?.IdEstado === 0 ? `col-12 subItems correctoCP` : `col-12 subItems pendienteCP`*/}>
                                                    <div className="row w-100" style={{ margin: '1px' }} >

                                                        <span style={{ fontSize: '13px' }}>{languageTranslate.moduloAutoChequeo.list.TituloListaControlPreventivo}</span>
                                                        <div className="col-10">

                                                            <div className="row" style={{ margin: '1px', padding: '8px 0' }}>
                                                                <div>
                                                                    <span className="text-danger">Nro. {item.Id}</span>
                                                                </div>
                                                                {/*
                                                                    <div>
                                                                        <span>{languageTranslate.moduloAutoChequeo.list.Estado}: </span><span className='text-muted'>{item.Estado.IdEstado === -1 ? 'Pendiente' : (item.Estado.IdEstado === 1 ? ("Activo") : ("Inactivo"))}</span>
                                                                    </div>
                                                                */}
                                                                <div>
                                                                    <span>{languageTranslate.moduloAutoChequeo.list.Resultado}: </span><span className='text-muted'>{item.ResultadoPreguntas === 0 ? (languageTranslate.moduloAutoChequeo.list.ResultadoItems.ResultadoCorrecto) : (languageTranslate.moduloAutoChequeo.list.ResultadoItems.ResultadoIncorrecto)}</span>
                                                                </div>
                                                                <div>
                                                                    <span>{languageTranslate.moduloAutoChequeo.list.Autor}: </span><span className='text-muted'>{data.Tecnico.Nombres} {data.Tecnico.Apellidos}</span>
                                                                </div>
                                                                <div>
                                                                    <span>{languageTranslate.moduloAutoChequeo.list.FechaRegistro}: </span>
                                                                    {item.FechaRegistro?.FechaRegistro && <span className='text-muted'> {AdapterGenerico.convertDateToString(new Date(item.FechaRegistro.FechaRegistro), 2)}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-2 d-flex  flex-column subItemsOption' style={{ alignItems: 'center', gap: 5 }}>


                                                            {
                                                                <>
                                                                    <i onClick={() => props.redirectPreview(item._id + "")} className="icon-custom mt-2 fa-solid fa-eye" style={{ fontSize: '18px' }} />

                                                                    {
                                                                        item.Estado.IdEstado !== -1 ?
                                                                        <i onClick={() => {
                                                                            let params = {
                                                                                IdControlPreventivo: item.IdControlPreventivo,
                                                                                Tecnico: item.Tecnico.Identificacion,
                                                                                Id: item.Id
                                                                            }
                                                                            props.openModalPDF(params);
                                                                        }} className="icon-custom mt-2 fa-solid fa-file-pdf" style={{ fontSize: '18px' }} />
                                                                        : null
                                                                    }
                                                                </>

                                                            }
                                                            {
                                                                /* item?.EstadoCP?.IdEstado === 10 && (
                                                                    <>
                                                                        <i onClick={() => props.redirectGestion(item._id)} className="icon-custom mt-2 fa-solid fa-share-from-square" style={{ fontSize: '18px' }} />
                                                                    </>
                                                                )*/
                                                            }
                                                        </div>

                                                        { /* Comentarios */ }
                                                        <div className='col-12 d-flex gap-1 px-2 pb-2' style={{ placeContent: 'end' }}>
                                                                <div className='icon-circle-base' style={{ background: (item.Puntuacion || []).length > 0 ? AdapterConfigure.COLOR_CALIFICACION[(item.Puntuacion || [])[(item.Puntuacion?.length || 0) - 1].Puntuacion] : "#929292" }} onClick={() => props.openModalListaComentarios(item)}>
                                                                <i className="fa-sharp fa-solid fa-star" style={{ fontSize: 12, color: 'white' }}></i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>


                            </div>
                        </div>

                    )
                }
            </div>
        </>
    )
}