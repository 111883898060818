import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import { EntityInitialData, EntityRequestRevision } from "../Domain/Utilts";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async initialData(): Promise<EntityInitialData> {
        const delegacion = await this.dbLocal.selectAllStore('Delegacion');

        return ({
            personal: AdapterGenerico.formatoPersonalizadoSelect(AdapterData.personal, 'Identificacion', ['Nombres', 'Apellidos']) as EntitySelectBase<EntityPersonal>[],
            delegacion: AdapterGenerico.formatoPersonalizadoSelect(delegacion, 'IdDelegacion', ['Codigo', 'Delegacion'], '-') as EntitySelectBase<EntityDelegacion>[]
        })
    }

    public async getList(params: any): Promise<EntityAutochequeo[] | null> {
        return await this._getList(params);
    }

    private async _getList(params: any): Promise<EntityAutochequeo[] | null> {
        let url: string = `${this.urlBase}${AdapterConfigure.LIST_CONTROLPREVENTIVO}`;
        const response: EntityAutochequeo[] = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", 'json', {}, 0);
        let data: EntityAutochequeo[] = await this.customClearAutochequeo();
        response.push(...data);

        await this.dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: response });
        return response.filter(row => ![-1, -2].includes(row.Estado.IdEstado));
    }

    public async getListGeneric(params: any): Promise<EntityAutochequeo[] | null> {
        let url: string = `${this.urlBase}${AdapterConfigure.LIST_CONTROLPREVENTIVO}`;
        const response: EntityAutochequeo[] = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", 'json', {}, 0);
        await this.dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: response });
        return response;
    }

    public async DownloadAudio(url: string): Promise<Blob | null> {
        return await this._DownloadAudio(url);
    }

    public async _DownloadAudio(url: string): Promise<Blob | null> {
        const response: Blob = await this.service.bgCall<any>("GET", url, JSON.stringify({}), "basicNewBackend", "json", 'blob', {}, 0);
        const audioData = await response.arrayBuffer();
        const blob = new Blob([audioData], { type: 'audio/mpeg' });
        return blob;
    }

    public async DownloadFile(url: string): Promise<File | null> {
        return await this.service.bgCall<any>("GET", url, JSON.stringify({}), "basic", "json", 'blob', {}, 0);
    }

    public async saveRevision(params: EntityRequestRevision, currentRegister: EntityAutochequeo): Promise<EntityAutochequeo | null> {
        const url = `${this.urlBase}/send/RevisionLA`
        const response = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", "json", {}, 0);
        if (response) this.dbLocal.updateByIndexStore({ nameStore: 'Autochequeo', value: { ...currentRegister, ...response } });
        return { ...currentRegister, ...response };
    }
}