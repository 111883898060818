import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { ElementGroupModule } from './ElementGroupModule';
import './Style.scss';

interface PropsViewMain {
    informationDataInitial: EntityInformationDataInitial;
    onClickLoadCatalogo: Function;
    onClickLoadAll: Function;
    onClickLoadModule: (listModule: string) => Promise<void>
};

const ModuleGeneral = ['OT', 'Empresa', 'Personal', 'Delegacion'];
const ModuleInspecciones = [ 'Area', 'Actividad', 'FormatoInspecciones', 'TipoInspeccion' ];
const ModuleAutochequeoAndCP = [ 'Actividad', 'AutochequeoTitulos', 'AutochequeoTipologia', 'AutochequeoItems', 'CTPreguntaLA', 'CTTipoPregunta' ];
const ModuleCharlaPretarea = [ 'Actividad', 'CTEPCS', 'CTMedidaPreventivas', 'CTOpcionAudio', 'CTOpcionLA', 'CTPreguntaLA', 'CTTipoPregunta', 'CTTIpoRiesgo' ];
const ModuleRDI = [ 'Pais', 'Actividad', 'FactorRiesgo_v2', 'TipoIncidente' ];

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className='ViewVariable'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <div className="row mb-2 mt-2 ml-1 position-relative">
                    <ElementBreadCrumb list={[{ text: languageTranslate.textoCatalogos, navigate: false, path: '' }]} />
                    <button className='position-absolute btn btn-sm btn-primary w-auto btn-accion' onClick={() => { props.onClickLoadAll(); }}>
                        <i className="fa-solid fa-refresh" style={{ paddingRight: '5px' }} />{languageTranslate.moduloCatalogo.textoRefrescarTodo}
                    </button>
                </div>

                <ElementGroupModule
                    informationDataInitial={props.informationDataInitial}
                    listCatalogo={ModuleGeneral}
                    reloadGroup={props.onClickLoadModule}
                    reloadItem={props.onClickLoadCatalogo}
                    title='General'
                    moduleKey='General'
                />

                <ElementGroupModule
                    informationDataInitial={props.informationDataInitial}
                    listCatalogo={ModuleInspecciones}
                    reloadGroup={props.onClickLoadModule}
                    reloadItem={props.onClickLoadCatalogo}
                    title='Inspecciones / OPS'
                    moduleKey='ModuloInspecciones'
                />

                <ElementGroupModule
                    informationDataInitial={props.informationDataInitial}
                    listCatalogo={ModuleAutochequeoAndCP}
                    reloadGroup={props.onClickLoadModule}
                    reloadItem={props.onClickLoadCatalogo}
                    title='Autochequeo / Control Preventivo'
                    moduleKey='AutochequeoCP'
                />

                <ElementGroupModule
                    informationDataInitial={props.informationDataInitial}
                    listCatalogo={ModuleCharlaPretarea}
                    reloadGroup={props.onClickLoadModule}
                    reloadItem={props.onClickLoadCatalogo}
                    title='Charla Pretarea'
                    moduleKey='CharlaPretarea'
                />

                <ElementGroupModule
                    informationDataInitial={props.informationDataInitial}
                    listCatalogo={ModuleRDI}
                    reloadGroup={props.onClickLoadModule}
                    reloadItem={props.onClickLoadCatalogo}
                    title='RDI'
                    moduleKey='RDI'
                />

                {/*Object.keys(props.informationDataInitial).map((key: string) => (
                    <ElementItemCard
                        key={key}
                        textHeader={key}
                        textSubHeader={
                            <span style={{ fontSize: '13px', paddingLeft: '5px', color: 'black' }} onClick={() => props.onClickLoadCatalogo(key)}>
                                <i className="fa-solid fa-refresh" style={{ fontSize: '14px', cursor: 'pointer', paddingRight: '10px' }} />{languageTranslate.moduloCatalogo.textoRefrescar}
                            </span>
                        }
                        ago={!props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? '' : AdapterGenerico.calculoTiempo(props.informationDataInitial[key as keyof typeof props.informationDataInitial].date as Date, languageTranslate.code as 'es' | 'en' | 'fr' | 'it')}
                        state={props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? languageTranslate.moduloCatalogo.textoSincronizado : languageTranslate.moduloCatalogo.textoNoSincronizado}

                    />
                ))*/}
            </div>
        </div >
    )
};