import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { EntityDelegacion } from '../Domain/EntityDelegacion';
import { EntityFuncionalidad } from '../Domain/EntityFuncionalidad';
import { EntityMenu } from '../Domain/EntityMenu';
import { EntityOTs } from '../Domain/EntityOTs';
import { EntityPais } from '../Domain/EntityPais';
import { EntityPersonal } from '../Domain/EntityPersonal';
import { EntityRol } from '../Domain/EntityRol';
import { AdapterStorage } from './AdapterStorage';
import { EntitySelectBase } from '../Domain/EntitySelectBase';
import { EntityEmpresa } from '../Domain/EntityEmpresa';

var { auth, token, tokenRefresh, user, menu, rol, funcionalidad, permisoVariables } = AdapterStorage.get(['auth', 'token', 'tokenRefresh', 'user', 'menu', 'rol', 'funcionalidad', 'permisoVariables']);

if (!!auth && user !== null) {
    var { [`preference${(user as EntityDataUsuario).IdUsuario}`]: preference } = AdapterStorage.get(`preference${(user as EntityDataUsuario).IdUsuario}`);
}

interface SliceAuthenticationState {
    auth: boolean;
    token: string | null;
    tokenRefresh: string | null;

    user: EntityDataUsuario;

    menu: Array<EntityMenu>;
    rol: EntityRol;
    funcionalidad: EntityFuncionalidad;

    permisoVariables: {
        arrIdPaises: Array<number>;
        arrIdGrupos: Array<number>;
        arrIdDelegaciones: Array<number>;
        arrIdOT: Array<number>;
    };

    preferencia: { pais: EntityPais | null, delegacion: EntityDelegacion | null, ot: EntityOTs | null, personal: EntityPersonal[] | null, buscarCuadrilla: boolean | null, automatizaPersonal: boolean | null, empresa: EntitySelectBase<EntityEmpresa> | null, forceOffline: boolean | null, appv2: boolean | null };
};

const userDefault: EntityDataUsuario = {
    _id: '0000000000000000',
    IdUsuario: 0,
    Usuario: 'INVITADO',
    ApellidoMaterno: 'SAFEIS',
    ApellidoPaterno: 'INVITADO',
    Nombres: 'USUARIO',
    DatosPersonal: [],
    DatosRol: { IdRol: 0, Codigo: '', Rol: '' },
    Delegacion: [],
    Email: '',
    Empresa: { IdEmpresa: 0, Nombre: '' },
    Foto: '',
    Identificacion: '',
    Pais: [],
    PaisBase: { IdPais: 0, Pais: '' },
    Perfil: { Code: '', Name: '' },
    TipoOperacion: [],
    TipoPerfil: { Code: '', Name: '' },
    DelegacionBase: { Codigo: '', Delegacion: '', IdDelegacion: 0 }
};

const menuDefault: Array<EntityMenu> = [
    // RDI
    { id: 50, codigo: '050', codigoModule: 206, icono: 'fa-solid fa-person-falling-burst', orden: 50, padre: null, ruta: 'main/RDI', sigla: 'SGSOAPP_RDI', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 51, codigo: '051', codigoModule: 206, icono: 'fa-solid fa-person-falling-burst', orden: 51, padre: null, ruta: 'main/RDI/form', sigla: 'SGSOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: true, estado: true },

    // Charla Pretarea
    { id: 31, codigo: '031', codigoModule: 203, icono: 'fa-solid fa-voicemail', orden: 31, padre: null, ruta: 'main/GestionAutochequeo', sigla: 'SGSOAPP_CHARLA', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 999, codigo: "999", codigoModule: 999, icono: "fa-solid fa-bars", orden: 999, padre: null, ruta: "main/CharlaPretarea", sigla: "SGSOAPP_CHARLA_", esOpcion: false, permitirSubOpcion: true, estado: true },

    // Gestión Lista CPT
    { id: 60, codigo: '060', codigoModule: 205, icono: 'fa-solid fa-bars', orden: 60, padre: null, ruta: 'main/GestionAutochequeo', sigla: 'SGSOAPP_GESAUTO', esOpcion: false, permitirSubOpcion: true, estado: true },
    { id: 61, codigo: "061", codigoModule: 205, icono: "fa-solid fa-bars", orden: 61, padre: null, ruta: "main/GestionAutochequeo", sigla: "SGSOAPP_GESAUTOId", esOpcion: false, permitirSubOpcion: true, estado: true },

];

const initialState: SliceAuthenticationState = {
    auth: !!auth ? true : false,
    token,
    tokenRefresh,
    user: !!user ? user : userDefault,
    menu: !!menu ? menu : menuDefault,
    // menu: !!menu ? menu : [],
    rol: !!rol ? rol : {},
    funcionalidad: !!funcionalidad ? funcionalidad : [],

    permisoVariables: !!permisoVariables ? permisoVariables : {
        arrIdPaises: [],
        arrIdGrupos: [],
        arrIdDelegaciones: [],
        arrIdOT: [],
    },

    preferencia: !!preference ? preference : { pais: null, delegacion: null, ot: null }
};


const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        signIn: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set('auth', true);
            AdapterStorage.set('user', payload.user);
            AdapterStorage.set('menu', payload.menu);
            // AdapterStorage.set('language', payload.language);

            return {
                ...state,
                auth: true,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
                user: payload.user,
                menu: payload.menu,
            };
        },
        signOut: state => {
            AdapterStorage.remove(['auth', 'token', 'tokenRefresh', 'user', 'menu']);

            return {
                ...state,
                user: userDefault,
                menu: menuDefault,
                auth: false,
                token: null,
                tokenRefresh: null,
            };
        },
        refreshTokenSisace: (state, { payload }: PayloadAction<any>) => {
            return {
                ...state,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
            };
        },

        changePreference: (state, { payload }: PayloadAction<any>) => {
            let preferencia = {
                ...state.preferencia,
                ...payload
            };

            AdapterStorage.set(`preference${state.user.IdUsuario}`, preferencia);

            return { ...state, preferencia };
        },

        changePermisoVariable: (state, { payload }: PayloadAction<any>) => {
            let permisoVariables = {
                ...state.permisoVariables,
                arrIdPaises: payload.arrIdPaises,
                arrIdGrupos: payload.arrIdGrupos,
                arrIdDelegaciones: payload.arrIdDelegaciones,
                arrIdOT: payload.arrIdOT,
            };

            AdapterStorage.set('permisoVariables', permisoVariables);

            return { ...state, permisoVariables };
        },

        signInInvite: state => {
            AdapterStorage.set('auth', true);
            return {
                ...state,
                auth: true,
            };
        }
    },
});

export const {
    signIn,
    signOut,
    refreshTokenSisace,
    changePreference,
    signInInvite,
    changePermisoVariable
} = authenticationSlice.actions;

export default authenticationSlice.reducer;